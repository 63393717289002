<template>
    <div :key="pageKey">
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-0 pl-5 pr-4>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <div>
                        <v-sheet class="dense-inputs">
                            <v-row no-gutters>
                                <v-col class="d-flex align-center pt-3 cols-md-12 cols-lg-6">
                                    <v-autocomplete
                                        :items="filterItems.salesTeam"
                                        :placeholder="$t('message.salesTeam')"
                                        class="ml-0 filter-width-175"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        v-model="filterValues.salesTeam"
                                        @change="filterDeals()"
                                        v-if="false"
                                    ></v-autocomplete>
                                    <v-autocomplete
                                        :items="filterItems.owner"
                                        :placeholder="$t('message.owner')"
                                        class="ml-0 filter-width-175"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-text="Owner.title"
                                        item-value="Owner.id"
                                        solo
                                        v-model="filterValues.owner"
                                        @change="filterDeals()"
                                    ></v-autocomplete>
                                    <v-autocomplete
                                        :items="filterItems.prospect"
                                        :placeholder="$t('message.buyerProspect')"
                                        class="ml-2 filter-width-175"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-text="Prospect.title"
                                        item-value="Prospect.id"
                                        solo
                                        v-model="filterValues.buyerProspect"
                                        @change="filterDeals()"
                                    ></v-autocomplete>
                                    <v-autocomplete
                                        :items="filterItems.stage"
                                        :placeholder="$t('message.stage')"
                                        class="ml-2 filter-width-175"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        v-model="filterValues.stage"
                                        @change="filterDeals()"
                                    ></v-autocomplete>
                                    <v-btn
                                        :loading="loading.clear"
                                        class="ml-2"
                                        @click="clearFilters"
                                    >{{ $t('message.clear') }}</v-btn>
                                </v-col>
                                <v-col class="cols-md-12 cols-lg-6 pt-5">
                                    <div class="d-flex flex-row">
<!--                                        <div class="font-sm d-flex flex-row align-center">-->
<!--                                            <div class="node start"><span>S</span></div><span class="ml-1">{{ $t('message.hubSpotStages.start') }}</span>-->
<!--                                        </div>-->
                                        <div class="font-sm d-flex flex-row align-center">
                                            <div class="node white"><span>1</span></div>
                                            <div class="d-flex flex-column">
                                                <span class="ml-1">{{ $t('message.hubSpotStages.prospectIdentified') }}</span>
                                                <span class="ml-1 font-weight-bold">{{ '$' + formatThisNumber(stageTotals.prospectIdentified) + ' (' + stageDeals.prospectIdentified.toString() + ')' }}</span>
                                            </div>
                                        </div>
                                        <div class="font-sm ml-4 d-flex flex-row align-center">
                                            <div class="node blue"><span>2</span></div>
                                            <div class="d-flex flex-column">
                                                <span class="ml-1">{{ $t('message.hubSpotStages.needsDiscovered') }}</span>
                                                <span class="ml-1 font-weight-bold">{{ '$' + formatThisNumber(stageTotals.needsDiscovered) + ' (' + stageDeals.needsDiscovered.toString() + ')' }}</span>
                                            </div>
                                        </div>
                                        <div class="font-sm ml-4 d-flex flex-row align-center">
                                            <div class="node green"><span>3</span></div>
                                            <div class="d-flex flex-column">
                                                <span class="ml-1">{{ $t('message.hubSpotStages.offerSent') }}</span>
                                                <span class="ml-1 font-weight-bold">{{ '$' + formatThisNumber(stageTotals.offerSent) + ' (' + stageDeals.offerSent.toString() + ')' }}</span>
                                            </div>
                                        </div>
                                        <div class="font-sm ml-4 d-flex flex-row align-center">
                                            <div class="node amber"><span>4</span></div>
                                            <div class="d-flex flex-column">
                                                <span class="ml-1">{{ $t('message.hubSpotStages.underNegotiation') }}</span>
                                                <span class="ml-1 font-weight-bold">{{ '$' + formatThisNumber(stageTotals.underNegotiation) + ' (' + stageDeals.underNegotiation.toString() + ')' }}</span>
                                            </div>
                                        </div>
                                        <div class="font-sm ml-4 d-flex flex-row align-center">
                                            <div class="node end"><span>Act</span></div>
                                            <div class="d-flex flex-column">
                                                <span class="ml-1">{{ $t('message.hubSpotStages.act') }}</span>
                                                <span class="ml-1 font-weight-bold">{{ '$' + formatThisNumber(stageTotals.all) + ' (' + stageDeals.all.toString() + ')' }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-row pt-3 pb-0 align-center">
                                        <div class="font-sm">
                                            <v-icon small color="blue lighten-1">fiber_manual_record</v-icon>{{ $t('message.lastUpdate') + ' < 7days'}}
                                        </div>
                                        <div class="font-sm ml-3">
                                            <v-icon small color="orange darken-1">fiber_manual_record</v-icon>{{ $t('message.noMovement') + ' > 60days' }}
                                        </div>
                                        <div class="font-sm ml-3">
                                            <v-icon small color="red darken-1">fiber_manual_record</v-icon>{{ $t('message.age') + ' > 120days' }}
                                        </div>
                                    </div>
                                </v-col>
                                <div class="pt-3">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on: menu }">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on: tooltip }">
                                                    <v-btn
                                                        height="36px"
                                                        small
                                                        v-on="{...tooltip, ...menu}"
                                                    >
                                                        <v-icon small dark v-on>ti ti-export</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t('message.export') }}</span>
                                            </v-tooltip>
                                        </template>
                                        <v-list dense>
                                            <v-list-item @click="exportTable('pdf')">
                                                <v-list-item-title>{{ $t('message.exportAsPdf') }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="exportTable('excel')">
                                                <v-list-item-title>{{ $t('message.exportAsExcel') }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </v-row>
                        </v-sheet>
                        <v-overlay
                            :value="loading.deals"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                    ></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-data-table
                            :headers="headers"
                            :items="deals"
                            :hide-default-footer="true"
                            :disable-pagination="true"
                            :height="tableHeight"
                            :items-per-page="-1"
                            :sort-by="['Deal.currentStage']"
                            :sort-desc="[false]"
                            class="mt-3 appic-table-light specification-table"
                            dense
                            fixed-header
                            ref="dealsTable"
                            item-key="Deal.id"
                        >
                            <template v-slot:item.Deal.id="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-btn icon @click="openDealInHubSpot(item)" v-on="{...tooltip}">
                                            <v-icon class="mdi mdi-hubspot" style="color: #FF7A59 !important;"></v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('message.openHubSpotDeal') }}</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:item.Deal.create_date="{ item }">
                                <span class="font-weight-bold">{{ item.Deal.create_date != '0000-00-00' ? formatDate(item.Deal.create_date) : '' }}</span>
                            </template>
                            <template v-slot:item.Deal.status="{ item }">
                                <div class="text-center">
                                    <v-icon small color="blue darken-1" v-if="item.Deal.stage_history[item.Deal.stage_history.length - 2]['daysToNextStage'] < 7">fiber_manual_record</v-icon>
                                    <v-icon small color="orange darken-1" v-if="item.Deal.stage_history[item.Deal.stage_history.length - 2]['daysToNextStage'] > 60">fiber_manual_record</v-icon>
                                    <v-icon small color="red darken-1" v-if="item.Deal.age > 120">fiber_manual_record</v-icon>
                                </div>
                            </template>
                            <template v-slot:item.Deal.owner="{ item }">
                                <span class="font-weight-bold">{{ item.Deal.owner }}</span>
                            </template>
                            <template v-slot:item.Deal.buyer="{ item }">
                                {{ item.Deal.buyer }}
                            </template>
                            <template v-slot:item.Deal.name="{ item }">
                                <div class="text-wrap" style="width: 100px !important;">{{ formatDescription(item.Deal) }}</div>
                            </template>
                            <template v-slot:item.Deal.amount="{ item }">
                                <span class="font-weight-bold">{{ '$' + formatThisNumber(item.Deal.amount,'0,0') }}</span>
                            </template>
                            <template v-slot:item.Deal.close_date="{ item }">
                                <span :class="checkStaleCloseDate(item.Deal.close_date) ? 'red--text darken-1' : ''">{{ item.Deal.close_date != '0000-00-00' ? formatDate(item.Deal.close_date) : '' }}</span>
                            </template>
                            <template v-slot:item.Deal.currentStage="{ item }">
                                <span class="text-no-wrap">{{ item.Deal.currentStage }}</span>
                            </template>
                            <template  v-slot:item.Deal.stage_history="{ item }">
                                <DealStageHistory :deal-stage-history="item.Deal.stage_history" :ref="'Deal_' + item.Deal.id" :id="'DealId_' + item.Deal.id"></DealStageHistory>
                            </template>
                            <template  v-slot:item.Deal.sc_note="{ item }">
                                <template v-if="$can('create','ScDealNote')">
                                    <DealNote
                                        :note="item.Deal.sc_note"
                                        :deal-id="item.Deal.id"
                                        :note-date="item.Deal.sc_note_date"
                                        type="sc"
                                    ></DealNote>
                                </template>
                                <template v-else>
                                    <div class="d-flex flex-row justify-space-between">
                                        <span>{{ item.Deal.sc_note }}</span>
                                        <span>{{ formatDate(item.Deal.sc_note_date) }}</span>
                                    </div>
                                </template>
                            </template>
                            <template  v-slot:item.Deal.dsm_note="{ item }">
                                <template v-if="$can('create','DsmDealNote')">
                                    <DealNote
                                        :note="item.Deal.dsm_note"
                                        :deal-id="item.Deal.id"
                                        :note-date="item.Deal.dsm_note_date"
                                        type="dsm"
                                    ></DealNote>
                                </template>
                                <template v-else>
                                    <div class="d-flex flex-row justify-space-between">
                                        <span>{{ item.Deal.dsm_note }}</span>
                                        <span>{{ formatDate(item.Deal.dsm_note_date) }}</span>
                                    </div>
                                </template>
                            </template>
                        </v-data-table>
                    </div>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import { api } from "Api";
import { formatDate, log, numberFormat } from "Helpers/helpers";

const DealStageHistory = () => import("Components/Appic/DealStageHistory");
const DealNote = () => import("Components/Appic/DealNote")

export default {
    name: "HubSpotDeals",
    components: { DealStageHistory, DealNote },
    data(){
        return {
            deals: [],
            filterItems: {
                salesTeam: [],
                prospect: [],
                owner: [],
                stage: [
                    {
                        text: this.$t('message.hubSpotStages.prospectIdentified'),
                        value: '1'
                    },
                    {
                        text: this.$t('message.hubSpotStages.needsDiscovered'),
                        value: '2'
                    },
                    {
                        text: this.$t('message.hubSpotStages.offerSent'),
                        value: '3'
                    },
                    {
                        text: this.$t('message.hubSpotStages.underNegotiation'),
                        value: '4'
                    }
                ]
            },
            filterValues: {
                buyerProspect: null,
                owner: null,
                stage: null,
                salesTeam: null
            },
            loader: false,
            loading: {
                filterItems: {
                    salesTeam: false,
                    owner: false,
                    prospect: false,
                    stage: false
                },
                clear: false,
                deals: false
            },
            pageKey: Math.floor(Math.random() * 100),
            stageDeals: {
                all: 0,
                prospectIdentified: 0,
                needsDiscovered: 0,
                offerSent: 0,
                underNegotiation: 0
            },
            stageTotals: {
                all: 0,
                prospectIdentified: 0,
                needsDiscovered: 0,
                offerSent: 0,
                underNegotiation: 0
            },
            tableHeight: '500',
            tableFixedHeader: true
        }
    },
    computed: {
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.open'),
                    value: 'Deal.id',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 width-1-pct',
                    sortable: true
                },
                {
                    id: 2,
                    text: this.$t('message.createDate'),
                    value: 'Deal.create_date',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 width-5-pct',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.owner'),
                    value: 'Deal.owner',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 width-8-pct',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.buyerProspect'),
                    value: 'Deal.buyer',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 width-10-pct',
                    sortable: true
                },
                {
                    id: 5,
                    text: this.$t('message.description'),
                    value: 'Deal.name',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 width-10-pct',
                    sortable: true
                },
                {
                    id: 6,
                    text: this.$t('message.amount'),
                    value: 'Deal.amount',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 width-5-pct',
                    sortable: true
                },
                {
                    id: 7,
                    text: this.$t('message.closeDate'),
                    value: 'Deal.close_date',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 width-5-pct',
                    sortable: true
                },
                {
                    id: 8,
                    text: this.$t('message.currentStage'),
                    value: 'Deal.currentStage',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 width-8-pct',
                    sortable: true
                },
                {
                    id: 9,
                    text: this.$t('message.age'),
                    value: 'Deal.age',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 width-3-pct',
                    sortable: true
                },
                {
                    id: 10,
                    text: this.$t('message.status'),
                    value: 'Deal.status',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 width-1-pct',
                    sortable: true,
                    // sort: (a, b) => {
                    //     log(a)
                    //     const lastMovementA = a.Deal.stage_history[a.Deal.stage_history.length - 2]['daysToNextStage']
                    //     const lastMovementB = b.Deal.stage_history[b.Deal.stage_history.length - 2]['daysToNextStage']
                    //     return lastMovementA < lastMovementB ? -1 : 1
                    // }
                },
                {
                    id: 11,
                    text: this.$t('message.stageMovement'),
                    value: 'Deal.stage_history',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1',
                    sortable: false
                },
                {
                    id: 12,
                    text: this.$t('message.yourNotes'),
                    value: 'Deal.sc_note',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 width-10-pct',
                    sortable: false
                },
                {
                    id: 13,
                    text: this.$t('message.dsmNotes'),
                    value: 'Deal.dsm_note',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 width-10-pct',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        checkStaleCloseDate (closeDate) {
            const date = new Date()
            const dateToCheck = new Date(closeDate)
            if(dateToCheck.getTime() > date.getTime()) {
                return false
            }
            return true
        },
        clearFilters () {
            this.filterValues.owner = null
            this.filterValues.buyerProspect = null
            this.filterValues.stage = null
            this.filterDeals()
        },
        async exportTable(reportType) {
            if (this.deals.length > 0) {
                let date = new Date()
                let day = date.getDate();
                let month = date.getMonth()+1;
                let year = date.getFullYear();
                let fullDate = year + "-" + month + "-" + day

                let reportFileName = 'Deals_List_' + fullDate
                let reportFileExtension = '.pdf'
                let reportFileType = 'application/pdf'
                if(reportType == 'excel'){
                    reportFileExtension = '.xlsx'
                    reportFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
                try {
                    this.$toast(this.$t('message.successes.exportedListReadyMoment'), {
                        classes: ['text--white'],
                        color: 'info',
                        timeout: 0,
                        icon: 'check',
                        iconColor: 'white',
                        x: 'center',
                        y: 'top'
                    })

                    api
                        .post('/hubspot/deals/export', {
                            data: {
                                deals: this.deals,
                            },
                            fields: this.headers,
                            type: reportType
                        }, {
                            responseType: 'blob'
                        })
                        .then((file) => {
                            const url = window.URL.createObjectURL(new Blob([file.data], {type: reportFileType}));
                            const link = document.createElement('a');
                            link.href = url;
                            link.target = '_blank'
                            link.setAttribute('download', reportFileName + reportFileExtension);
                            link.click();
                            link.remove();
                            window.URL.revokeObjectURL(url);
                            //close notification
                            let cmp = this.$toast.getCmp()
                            cmp.close()
                        })
                        .catch((error) => {
                            let cmp = this.$toast.getCmp()
                            cmp.close()
                            this.$toast.error(this.$t('message.errors.couldNotExportList') + ": " + error, {
                                timeout: 7000,
                                x: 'center',
                                y: 'top'
                            })
                        })
                } catch (error) {
                    let cmp = this.$toast.getCmp()
                    cmp.close()
                    this.$toast.error(this.$t('message.errors.couldNotExportList') + ": " + error, {
                        timeout: 7000,
                        x: 'center',
                        y: 'top'
                    })
                }
            } else {
                alert(this.$t('message.noDataToExport'))
            }
        },
        // fetchDeals() {
        //     return new Promise((resolve, reject) => {
        //
        //     })
        // },
        filterDeals() {
            return new Promise((resolve, reject) => {
                let conditions = [];
                if(this.filterValues.owner != null){
                    conditions.push({field: 'Deal.owner_id', value: this.filterValues.owner})
                }
                if(this.filterValues.buyerProspect != null){
                    conditions.push({field: 'Deal.company_id', value: this.filterValues.buyerProspect})
                }
                if(this.filterValues.stage != null){
                    conditions.push({field: 'Deal.stage', value: this.filterValues.stage})
                }
                this.loading.deals = true
                api
                    .get('/hubspot/deals', {
                        params: {
                            conditions: conditions,
                        }
                    })
                    .then((response) => {
                        if(response.data.status == 'success') {
                            this.deals = response.data.data

                            //update totals
                            this.stageTotals.all = 0
                            this.stageTotals.prospectIdentified = 0
                            this.stageTotals.needsDiscovered = 0
                            this.stageTotals.offerSent = 0
                            this.stageTotals.underNegotiation = 0

                            this.stageDeals.all = 0
                            this.stageDeals.prospectIdentified = 0
                            this.stageDeals.needsDiscovered = 0
                            this.stageDeals.offerSent = 0
                            this.stageDeals.underNegotiation = 0

                            response.data.data.forEach(deal => {
                                switch(deal['Deal']['currentStageCode']){
                                    case '1':
                                        this.stageTotals.prospectIdentified += deal['Deal']['amount']
                                        this.stageTotals.all += deal['Deal']['amount']
                                        this.stageDeals.prospectIdentified ++;
                                        this.stageDeals.all ++;
                                        break
                                    case '2':
                                        this.stageTotals.needsDiscovered += deal['Deal']['amount']
                                        this.stageTotals.all += deal['Deal']['amount']
                                        this.stageDeals.needsDiscovered ++;
                                        this.stageDeals.all ++;
                                        break
                                    case '3':
                                        this.stageTotals.offerSent += deal['Deal']['amount']
                                        this.stageTotals.all += deal['Deal']['amount']
                                        this.stageDeals.offerSent ++;
                                        this.stageDeals.all ++;
                                        break
                                    case '4':
                                        this.stageTotals.underNegotiation += deal['Deal']['amount']
                                        this.stageTotals.all += deal['Deal']['amount']
                                        this.stageDeals.underNegotiation ++;
                                        this.stageDeals.all ++;
                                        break
                                }
                            })
                            resolve('done')
                        } else {
                            reject(response.data.status)
                        }
                        this.loading.deals = false
                    })
                    .catch((error) => {
                        this.$toast.error(this.$t('message.error') + ': ' + error,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        reject('error')
                        this.loading.deals = false
                    });
            })
        },
        formatDate,
        formatDescription (deal) {
            let buyer = deal.buyer
            let description = deal.name
            description = description.replace(buyer, '')
            description = description.replace(' - ', '')
            description = description.replace('-', '')
            return description
        },
        formatThisNumber(value,format){
            return numberFormat(value,format)
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (200);
        },
        loadFilterItems(filter) {
            return new Promise((resolve, reject) => {
                if(filter && filter.length > 0) {
                    this.loading.filterItems[filter] = true
                        api
                            .get('/hubspot/deals/filter-options/' + filter)
                            .then(response => {
                                if (response.data.status == 'success') {
                                    this.filterItems[filter] = response.data.data
                                    this.loading.filterItems[filter] = false
                                    resolve('done')
                                } else {
                                    this.loading.filterItems[filter] = false
                                    reject('error')
                                }
                            })
                            .catch(error => {
                                this.loading.filterItems[filter] = false
                                reject(error)
                            })
                } else {
                    reject('Filter empty')
                }
            })
        },
        openDealInHubSpot(item) {
            window.open(
                process.env.VUE_APP_HUBSPOT
                + '/contacts/'
                + item.Deal.main_contact_id
                + '/deal/'
                + item.Deal.id
                , "_blank")
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        if(this.filterItems.salesTeam.length == 0) this.loadFilterItems('salesTeam')
        if(this.filterItems.owner.length == 0) this.loadFilterItems('owner')
        if(this.filterItems.prospect.length == 0) this.loadFilterItems('prospect')
    },
    mounted() {
        this.handleResize()
        this.$title = this.$t('message.titles.hubSpotDeals')
        this.filterDeals()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>
.filter-width-175 {
    width: 175px !important;
    max-width: 175px !important;
}
.v-data-table::v-deep .text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
}
.v-data-table::v-deep td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.v-data-table::v-deep th {
    white-space: nowrap;
}
.node {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border-color: black;
    border-width: 2px;
    border-style: solid;
    background-color: #3C6932;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.node::v-deep span {
    color: white;
    font-size: 0.9em;
}
.node.start {
    background-color: grey !important;
}
.node.end {
    background-color: grey !important;
}
.node.white {
    background-color: white !important;
    border-color: black !important;
}
.node.blue {
    background-color: #1E88E5 !important;
    border-color: black !important;
}
.node.green {
    background-color: #558B2F !important;
    border-color: black !important;
}
.node.amber {
    background-color: #FFAB00 !important;
    border-color: black !important;
}
.node.white::v-deep span {
    color: black !important;
    font-size: 0.9em;
    font-weight: bold !important;
}
.node.amber::v-deep span {
    color: black !important;
    font-size: 0.9em;
    font-weight: bold !important;
}
</style>